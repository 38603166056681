<template>
  <div style="display:flex; flex-direction:row;">
    <kendo-colorpicker
      :id="'colorPickerId'"
      :ref="'colorPickerRef'"
      :palette="paletteArray"
    ></kendo-colorpicker>
    <!-- <div>TBD Description</div> -->
  </div>
</template>

<script>

export default {
  name: 'color-template',
  data () {
    return {
      paletteArray: ['#BFD7EA', '#9B7EDE', '#832161', '#52050A', '#76E7CD', '#FF6B35', '#0ABAB5', '#62A8AC', '#F4D6CC', '#899D78', '#F0CFC3', '#F0BCD4', '#F4B860', '#FFB86F', '#E0777D', '#E0CA3C']
    }
  }
}
</script>
