<template>
  <div>
    <input type="text" style="width: 80px;">
    <!-- <kendo-dropdownlist
      :ref="'picosDropdownlist'"
      :data-source="picosDataSource"
      :data-value-field="'PicosIdentity'"
      :data-text-field="'PicosName'"
      :placeholder="'select...'"
      style="width: auto;">
    </kendo-dropdownlist> -->
  </div>
</template>

<script>
import PicosDataSource from '@/assets/data/Picos.json'

export default {
  name: 'picos-template',
  data () {
    return {
      picosDataSource: PicosDataSource
    }
  }
}
</script>
