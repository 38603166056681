<template>
    <div class="keywords-tags">
      <ConfigurationHeaderContainer title="Keywords/Tags for Project X"></ConfigurationHeaderContainer>
      <div class="form-entry-container">
        <div class="form-entry-label">Keywords</div>
        <div class="form-entry-input">
          <div>
            <kendo-datasource
              :ref="'keywordDataSource'"
              :data="keywordDataSource.data">
            </kendo-datasource>
            <button class="k-button" @click="addKeyword">+Add Keyword...</button>
            <div v-if="addKeywordWindowVisible" style="border: solid red 1px; width: 1500px; display: flex; flex-direction: column;">
              <div>Add keyword</div>
              <div>
                <FormEntryContainer title="PICOS" type="dropdown" size="10" :datasource="showPicosDataSource" :textfield="'name'" :valuefield="'id'"></FormEntryContainer>
                <FormEntryContainer title="Population" type="picoseditor"
                  includevalue="<ul><li>Adult patients (&ge;18yrs) with advanced or metastatic NSCLC (AJCC stage IIIB, IIIC, or IV) who are previously untreated with systemic therapy for their advanced or metastatic disease (i.e., first-line) with PD-L1 expression tested<p align='left' style='line-height:normal;'><em>Subgroups of interest:</em></p>Histology: Non-squamous; squamous histology</li><li>PD-L1 expression level: &lt;1%; &ge;1%; 1-49%; &ge;50%; or other categories as reported (including unknown)</li><li>Patients without oncogene driver mutations</li><li>Smoking status: Current/former smoker; Never smoker</li><li>Eastern Cooperative Oncology Group performance status: ECOG=0; ECOG=1 </li><li>Ethnicity: Non-Asian; Asian</li><li>Race: white, non-white, other</li><li>Prior treatment experience: Newly diagnosed advanced; Progressed from lower stage to advanced stage</li><li>Disease stage: Metastatic; Non-metastatic (locally advanced)</li><li>Brain metastases at baseline: yes; no</li><li>Sex: males; females</li><li>Age: &lt;65 years; &ge;65 years</li></ul>"
                  excludevalue="<ul><li>Pediatric patients (&lt;18yrs)</li><li>Patients previously treated with systemic therapy for their advanced or metastatic disease (i.e., second- or subsequent line)</li><li>Populations comprised of 100% patients with known oncogene driver mutations (e.g., EGFR, ALK, ROS-1)<sup>a</sup></li></ul>"></FormEntryContainer>
                <div style="font-weight: 900; margin-top: 20px;">Keyword information</div>
                <FormEntryContainer title="Keyword name" type="text" size="50" :value="'Adult patients (≥18yrs) with advanced or metastatic NSCLC'"></FormEntryContainer>
                <FormEntryContainer title="Aliases" type="text" size="10" :value="projectNumber"></FormEntryContainer>
                <FormEntryContainer title="Group" type="dropdown" size="10" :value="projectNumber" :datasource="groupDataSource" :textfield="'name'" :valuefield="'id'"></FormEntryContainer>
                <FormEntryContainer title="Include" type="checkbox" size="10" :value="projectNumber" checked="true"></FormEntryContainer>
                <FormEntryContainer title="Exclude" type="checkbox" size="10" :value="projectNumber"></FormEntryContainer>
                <FormEntryContainer title="Data" type="checkbox" size="10" :value="projectNumber"></FormEntryContainer>
              </div>
              <div style="display: flex; justify-content: flex-end;">
                <button>Save</button>
                <button @click="cancelKeyword">Cancel</button>
              </div>
            </div>
            <kendo-grid
              :ref="'keywordGridB'"
              :data-source-ref="'keywordDataSource'"
              :editable="'inline'"
              :selectable="selectable"
              style="width: calc(100% - 150px);"
              :columns="keywordColumns">
              <!-- <kendo-grid-column field="KeywordName" title="Keyword Name" width="120px"></kendo-grid-column>
              <kendo-grid-column field="Aliases" title="Aliases" template='<input type="text" value="#:Aliases #">'></kendo-grid-column>
              <kendo-grid-column field="Type" title="Group" template='<select><option>Select an option..</option><option>PICOS/Population</option><option>PICOS/Interventions</option><option>PICOS/Comparators</option><option>PICOS/Outcomes</option><option>PICOS/Study Design</option><option>User Defined</option></select>' width="300px"></kendo-grid-column>
              <kendo-grid-column field="Include" title="Include" template='<input type="checkbox" #if (Include) { # checked # } #>'></kendo-grid-column>
              <kendo-grid-column field="Exclude" title="Exclude" template='<input type="checkbox" #if (Exclude) { # checked # } #>'></kendo-grid-column>
              <kendo-grid-column field="Data" title="Data" template='<input type="checkbox" #if (Data) { # checked # } #>'></kendo-grid-column>
              <kendo-grid-column field="Color" title="Color/Meaning" :template="colorTemplate"></kendo-grid-column>
              <kendo-grid-column field="PicosItemDescription" title=" " template="<button class='k-button'>Edit</button><button class='k-button'>Delete</button>"></kendo-grid-column> -->
            </kendo-grid>
          </div>
        </div>
        <div class="form-entry-label">Keyword Color Palette Definitions</div>
        <div class="form-entry-input">
          <div>
            <kendo-datasource
              :ref="'includeDefinitions'"
              :data="colorDataSource.data">
            </kendo-datasource>
            <kendo-grid
              :ref="'includeColorPalette'"
              :data-source-ref="'includeDefinitions'"
              style="width: 800px"
              :columns="definitionColumns">
              <kendo-grid-column field="Color" title="Color" width="150px" template="<div style='background-color: #: ColorCode #; width:100px;'>&nbsp;oo</div>"></kendo-grid-column>
              <kendo-grid-column field="ColorName" title="Name of Color" width="150px"></kendo-grid-column>
              <kendo-grid-column title="Meaning" width="320px">ii</kendo-grid-column>
              <!-- <kendo-grid-column field="ColorDescription" title="Keyword Name" width="120px"></kendo-grid-column> -->
            </kendo-grid>
          </div>
        </div>
        <div class="form-entry-label">Tags</div>
        <div class="form-entry-input">
        <kendo-treelistdatasource
              :id="'interventionTreeDataSource'"
              :ref="'interventionTreeDataSourceRef'"
              :data="interventionDataSource.data"
              :schema="interventionDataSource.schema">
            </kendo-treelistdatasource>
            <kendo-treelist
              :id="'interventionTreeView'"
              :ref="'interventionTreeViewRef'"
              :data-source-ref="'interventionTreeDataSourceRef'"
              :reorderable=true
              :editable=false
              :columns="interventionColumns"
              :toolbar="[{ name: 'createTag', text: 'Add new tag' }]">
            </kendo-treelist>
        </div>
      </div>
      <!-- <kendo-window
      :ref="'addKeywordWindow'"
      :modal="true"
      :visible="addKeywordWindowVisible"
      :width="addKeywordWindowWidth"
      :height="addKeywordWindowHeight"
      :title="addKeywordWindowTitle"
      :top="'0px'"
      :left="'0px'">ppp
    </kendo-window> -->
    </div>
</template>

<script>
import ConfigurationHeaderContainer from '@/containers/ConfigurationHeaderContainer'
import ColorTemplateRef from '@/views/secure/configuration/templates/Color'
import PicosTemplate from '@/views/secure/configuration/templates/Picos'
import KeywordDataSource from '@/assets/data/Keyword.json'
import ColorDataSource from '@/assets/data/Color.json'
import ColorIncludeDataSource from '@/assets/data/ColorInclude.json'
import ColorExcludeDataSource from '@/assets/data/ColorExclude.json'
import ColorDataDataSource from '@/assets/data/ColorData.json'
import FormEntryContainer from '@/containers/FormEntryContainer'

export default {
  name: 'keywords-tags',
  components: {
    ConfigurationHeaderContainer,
    FormEntryContainer
  },
  data () {
    return {
      addKeywordWidget: {},
      addKeywordWindowTitle: 'hi',
      addKeywordWindowWidth: '1050px',
      addKeywordWindowHeight: '700px',
      addKeywordWindowVisible: false,
      keywordDataSource: KeywordDataSource,
      colorDataSource: ColorDataSource,
      colorIncludeDataSource: ColorIncludeDataSource,
      colorExcludeDataSource: ColorExcludeDataSource,
      colorDataDataSource: ColorDataDataSource,
      colorTemplate: ColorTemplateRef,
      paletteArray: this.$store.state.paletteArray,
      selectable: true,
      tools: [],
      showPicosDataSource: {
        data: [
          { id: 1, name: 'Population' },
          { id: 2, name: 'Interventions' },
          { id: 3, name: 'Comparators' },
          { id: 4, name: 'Study Design' },
          { id: 5, name: 'Language' }
        ]
      },
      groupDataSource: {
        data: [
          { id: 1, name: 'PICOS/Population' },
          { id: 2, name: 'PICOS/Interventions' },
          { id: 3, name: 'PICOS/Comparators' },
          { id: 4, name: 'PICOS/Outcomes' },
          { id: 5, name: 'PICOS/Study Design' },
          { id: 6, name: 'User Defined' }
        ]
      },
      interventionDataSource: {
        data: [
          { NodeId: 1, NodeName: 'Tag 1' },
          { NodeId: 2, NodeName: 'Tag 2 - child 1', ReportsTo: 1 },
          { NodeId: 3, NodeName: 'Tag 3' },
          { NodeId: 4, NodeName: 'Tag 3 - child 1', ReportsTo: 3 },
          { NodeId: 5, NodeName: 'Tag 3 - child 1 - Subchild 1', ReportsTo: 4 }
        ],
        schema: {
          model: {
            id: 'NodeId',
            parentId: 'ReportsTo',
            expanded: true,
            batch: true,
            fields: {
              NodeId: { type: 'number', editable: false, nullable: false },
              NodeName: { validation: { required: true } },
              ReportsTo: { nullable: true, type: 'number' }
            }
          }
        }
      },
      interventionColumns: [
        { field: 'NodeName', title: 'Tag nodes', width: 220, expandable: true, headerAttributes: { class: 'grid-header-light' } },
        { command: [{ name: 'createchild', text: 'Add child' }, 'edit', 'destroy'], title: 'Node operations', width: 300, headerAttributes: { class: 'grid-header-light' } }
      ],
      filter: { field: 'Version', operator: 'eq', value: 'A' },
      keywordColumns: [
        {
          title: '',
          headerAttributes: { class: 'grid-header-light' },
          columns: [
            { field: 'KeywordName', title: 'Keyword Name', headerAttributes: { class: 'grid-header-light' }, width: '120px' },
            { field: 'Aliases', title: 'Aliases', headerAttributes: { class: 'grid-header-light' }, template: '<input type="text" value="#:Aliases #">' },
            { field: 'Type', title: 'Group', headerAttributes: { class: 'grid-header-light' }, template: '<select><option>Select an option..</option><option>PICOS/Population</option><option>PICOS/Interventions</option><option>PICOS/Comparators</option><option>PICOS/Outcomes</option><option>PICOS/Study Design</option><option>User Defined</option></select>', width: '300px' }
          ]
        },
        {
          title: 'Type',
          headerAttributes: { class: 'grid-header-light' },
          columns: [
            { field: 'Include', title: 'Include', headerAttributes: { class: 'grid-header-light' }, template: '<input type="checkbox" #if (Include) { # checked # } #>' },
            { field: 'Exclude', title: 'Exclude', headerAttributes: { class: 'grid-header-light' }, template: '<input type="checkbox" #if (Exclude) { # checked # } #>' },
            { field: 'Data', title: 'Data', headerAttributes: { class: 'grid-header-light' }, template: '<input type="checkbox" #if (Data) { # checked # } #>' }
          ]
        },
        {
          title: '',
          headerAttributes: { class: 'grid-header-light' },
          columns: [
            { field: 'Color', title: 'Color/Meaning', headerAttributes: { class: 'grid-header-light' }, template: '<div style="color: #:Color #;">#:Color #</div>' },
            { title: '', headerAttributes: { class: 'grid-header-light' }, template: '<button class="k-button">Edit</button>' },
            { title: '', headerAttributes: { class: 'grid-header-light' }, template: '<button class="k-button">Delete</button>' }
          ]
        }
      ],
      definitionColumns: [
        {
          title: 'Color',
          headerAttributes: { class: 'grid-header-light' },
          template: '<div style="background-color: #:ColorCode #;">&nbsp;</div>'
        },
        {
          title: 'Name of Color',
          headerAttributes: { class: 'grid-header-light' },
          field: 'ColorName'
        },
        {
          title: 'Meaning',
          headerAttributes: { class: 'grid-header-light' },
          field: 'ColorDescription'
        }
      ]
    }
  },
  methods: {
    addKeyword: function () {
      console.log(this.addKeywordWidget)
      this.addKeywordWindowVisible = true
      this.windowTitle = this.addKeywordWindowTitle
      this.windowWidth = '550px'
      this.windowHeight = '700px'
      this.windowVisible = this.addKeywordWindowVisible
      this.windowPosition = { top: 0, left: 40 }
      this.addKeywordWidget.title('Add Keyword')
      this.addKeywordWidget.close()
      // this.addKeywordWidget.open().center()
    },
    cancelKeyword: function () {
      this.addKeywordWindowVisible = false
    },
    editRow: function () {
      this.keywordWidget.editRow('tr:eq(' + (this.keywordWidget.select().index() + 1) + ')')
    },
    addRow: function () {
      this.keywordWidget.addRow()
    },
    deleteRow: function () {
      this.keywordWidget.removeRow('tr:eq(' + (this.keywordWidget.select().index() + 1) + ')')
    },
    colorTemplateRender: function (e) {
      return {
        template: this.ColorTemplate,
        templateArgs: e
      }
    },
    picosTemplate: function (e) {
      return {
        template: PicosTemplate,
        templateArgs: e
      }
    }
  },
  mounted: function () {
    // this.addKeywordWidget = this.$refs.addKeywordWindow.kendoWidget()
  }
}
</script>
